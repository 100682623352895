<template>
  <div>
    <CarouselPagePackage />
    <SearchBar />
    <Page />
    <RegisterAndLogin />
  </div>
</template>

<script>
export default {
  name: 'DetailPage',
  components: {
    RegisterAndLogin: () => import('../Layout/home/register_and_login'),
    Page: () => import('../Layout/page/detail'),
    CarouselPagePackage: () => import('../Layout/home/carousel'),
    SearchBar: () => import('../Layout/home/search_bar'),
  },
};
</script>
